<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('Storage.nav.SKU_information') }}
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('Storage.skuInfo.SKU_information') }}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip>
						<el-tooltip effect="dark" :content="'SKU条形码'" placement="top">
							<el-button type="primary" circle icon="el-icon-download" size="mini"
								@click="openDownCodePDF()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList" v-if="!isSel"> -->
					<!-- <li> -->
					<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus" v-if="!isSel">
						{{ $t('Storage.skuInfo.Add_SKU') }}</el-button>
					<!-- </li> -->
					<!-- <li> -->
					<el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload" v-if="!isSel">
						{{ $t('Storage.skuInfo.Import_SKU') }}</el-button>
					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							<!-- {{ item.label }} -->
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList" v-if="!isSel">
					<li>
						<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">
							{{ $t('Storage.skuInfo.Add_SKU') }}</el-button>
					</li>
					<li>
						<el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload">
							{{ $t('Storage.skuInfo.Import_SKU') }}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<!-- <li>
						<span>SKU:</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model.trim="filterData.goodsSku" :clearable="true" style="width: 200px;"></el-input>
						
					</li> -->
					<li>
						<span>SKU</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku"
							maxlength="50" :clearable="true" style="width: 200px;"></el-input>
						<el-tooltip class="" effect="dark" :content="'多SKU搜索'" placement="top">
							<el-button icon="el-icon-s-operation" size="small" type="success" plain
								@click="openSkuInputAction()"></el-button>
						</el-tooltip>

					</li>

					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>

		<!-- 多SKU搜索显示 -->
		<div class="" style="padding: 0 10px 10px 10px;"
			v-if="filterData.skuNumberList && filterData.skuNumberList.length > 0">
			<ul class="filterConList" style="text-align: left;">
				<el-button icon="el-icon-close" size="small" type="success" @click="clearSkuList()">
					<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
					(<span>多SKU搜索</span>)
					(<span>共<span>{{filterData.skuNumberList.length}}</span></span>)
				</el-button>
				<li v-for="(item, index) in filterData.skuNumberList" :key="index">
					<el-tag size="small" effect="plain">{{ item }}</el-tag>
				</li>
			</ul>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
			</div>
			<el-button type="primary" @click="initData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con" v-if="!isSel">
			<el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{ $t('Storage.skuInfo.Add_SKU') }}</el-button>
			<el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload">{{ $t('Storage.skuInfo.Import_SKU') }}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
				size="small" :max-height="$store.state.frameConHeightWh1" @sort-change="sortChange"
				:default-sort="{'prop':'createTime','order':'descending'}">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "id":"数据ID",
    "goodsSubjectId":"类目",
    "goodsSku":"SKU编码",
    "goodsName":"SKU名称",
    "goodsNameEn":"SKU英文标题",
    "goodsImg":"SKU图片",
    "declarePrice":"价值",
    "goodsWeight":"重量",
    "sizeUnit":"尺寸单位",
    "goodsLength":"长",
    "goodsWidth":"宽",
    "goodsHeight":"高",
    "safeStock":"安全库存"-->

				<!-- <el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
          <template slot-scope="scope">
            <el-tag type="" v-if="'1'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="success" v-else-if="'2'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="warning" v-else-if="'3'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="danger" v-else-if="'4'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
          </template>
        </el-table-column> -->
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>
				<el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column>
 -->
				<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'30'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column prop="goodsSku" label="SKU" :sortable="'custom'"></el-table-column>

				<!-- <el-table-column prop="sysGoodsSku" label="自定义SKU"></el-table-column> -->



				<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
					<template slot-scope="scope">
						<div>
							<!-- <div>{{scope.row.goodsName}}</div>
							<div v-if="scope.row.goodsName!=scope.row.goodsNameEn">{{scope.row.goodsNameEn}}</div> -->
							<el-popover placement="top" trigger="hover">
								<div class="pre-text">{{ scope.row.goodsName }}</div>
								<span class="over_h120" slot="reference">{{scope.row.goodsName}}</span>
							</el-popover>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="goodsLength"
					:label="$t('Storage.skuInfo.size')+'('+$t('Storage.skuInfo.length_width_height')+')'">
					<template slot-scope="scope">
						<div>
							<span>{{scope.row.goodsLength}}</span>*
							<span>{{scope.row.goodsWidth}}</span>*
							<span>{{scope.row.goodsHeight}}</span>
						</div>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
				<el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
				<el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
				 -->
				<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

				<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

				<el-table-column prop="sizeUnit"
					:label="$t('i18nn_96f1a4b17e75892d')+'/'+$t('i18nn_d5d972e6063a3180')+'(in/lb)'" min-width="150">
					<template slot-scope="scope2">
						<!-- <div v-if="scope2.row"> -->
						<div>

							<div>
								<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{scope2.row.goodsINCLength}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{scope2.row.goodsINCWidth}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{scope2.row.goodsINCHeight}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{scope2.row.goodsLBWeight}}</span>
							</div>
						</div>
						<!-- </div> -->
					</template>
				</el-table-column>

				<!-- <el-table-column prop="isBatteryName" :label="$t('i18nn_6d8f9b0c398319ff')">
					<template slot-scope="scope">
						<div v-if="scope.row.isBattery">
							<el-tag type="info" v-if="'0' == scope.row.isBattery">{{ $Utils.i18nKeyText(scope.row,'isBatteryName') }}</el-tag>
							<el-tag type="warning" v-else-if="'1' == scope.row.isBattery">{{ $Utils.i18nKeyText(scope.row,'isBatteryName') }}</el-tag>
							<el-tag type="" v-else>{{ $Utils.i18nKeyText(scope.row,'isBatteryName') }}</el-tag>
						</div>
					</template>
				</el-table-column> -->


				<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

				<el-table-column prop="goodsSku" :label="'SKU CODE'" width="400px">
					<template slot-scope="scope">
						<barcode :code="scope.row.goodsSku"></barcode>
					</template>
				</el-table-column>

				<el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
					<template slot-scope="scope">
						<div>
							<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg"
								:preview-src-list="[scope.row.goodsImg]">
								<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</div>
					</template>
				</el-table-column>


				<el-table-column prop="upcBrand" :label="'UPC'"></el-table-column>
				<!-- <el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column> -->

				<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
					<template slot-scope="scope">
						<div>
							<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
								<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
							</el-image>
						</div>
					</template>
				</el-table-column>

				
				<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
				<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>
 -->
				<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')" width="150" :sortable="'custom'">

				</el-table-column>

				<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')" width="150" :sortable="'custom'">

				</el-table-column>



				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<div v-if="isSel">
							<el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini"
								icon="el-icon-magic-stick">{{ $t('FormMsg.Select') }}</el-button>
						</div>
						<div v-else>
							<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
								icon="el-icon-edit" v-if="'0' == scope.row.status">{{ $t('FormMsg.Edit') }}</el-button>
							<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus"
								v-if="'0' == scope.row.status">{{ $t('FormMsg.Delete') }}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="$t('Storage.skuInfo.Add_SKU')" append-to-body
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4"> -->
		<el-drawer :wrapperClosable="false" :title="$t('Storage.skuInfo.Add_SKU')" append-to-body
			:visible.sync="dialogFormVisible" :direction="'rtl'" size="1200px">
			<!-- {
          "id":"数据ID",
              "goodsSubjectId":"类目",
              "goodsSku":"SKU编码",
              "goodsName":"SKU名称",
              "goodsNameEn":"SKU英文标题",
              "goodsImg":"SKU图片",
              "declarePrice":"价值",
              "goodsWeight":"重量",
              "sizeUnit":"尺寸单位",
              "goodsLength":"长",
              "goodsWidth":"宽",
              "goodsHeight":"高",
              "safeStock":"安全库存"
      } -->

			<el-form ref="form" :rules="formRules" :size="''" :model="form" label-width="100px" style="" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<div style="display:flex;">
					<div style="width:50%;">
						<!-- <el-form-item :label="$t('Storage.skuInfo.category')" prop="">
							<CommodityCateLinkage :width="400" :showLevel="1" :size="''"
								:placeholder="$t('Storage.skuInfo.cate_placeholder')" :clearable="true" :value="CateValue"
								@change="CateChang"></CommodityCateLinkage>

						</el-form-item> -->

						<!-- <el-form-item label="SKU" prop="goodsSku">
							<div class="form_msg">
								建议按照数字字母编码组合,<br />不超过30个字符,且保持唯一
							</div>
							<el-input type="text" v-model="form.goodsSku" style="width: 400px;"
								:placeholder="$t('FormMsg.Please_Enter')"></el-input>
							
						</el-form-item> -->

						<el-form-item label="SKU" prop="sysGoodsSku">
							<div class="form_msg">
								<!-- 建议按照$t('i18nn_5d819cd0462cbe89')、$t('i18nn_dfa40fe9cb22797d')、'-'组合,不超过20个字符,且保持唯一 -->
								{{$t('Storage.skuInfo.sku_explain')}}
								<!-- <br />系统将根据【自定义SKU】生成标准SKU,仓库将按标准SKU操作 -->
							</div>
							<el-input type="text" v-model.trim="form.sysGoodsSku" style="width: 400px;"
								:placeholder="$t('FormMsg.Please_Enter')" maxlength="40" show-word-limit></el-input>
						</el-form-item>



						<el-form-item :label="$t('Storage.skuInfo.SKU_title')" prop="goodsName">
							<!-- <el-input :placeholder="$t('FormMsg.Please_Enter')" v-model="form.goodsNameEn"
								style="width: 400px; margin-bottom: 20px;">
								<template slot="prepend">
									{{ $t('Storage.skuInfo.English') }}
								</template>
							</el-input> -->

							<el-input :placeholder="$t('FormMsg.Please_Enter')" v-model="form.goodsName" :maxlength="200"
								show-word-limit style="width: 400px;">
								<!-- <template slot="prepend">
									{{ $t('Storage.skuInfo.Chinese') }}
								</template> -->
							</el-input>

						</el-form-item>

						<!-- <el-form-item :label="$t('i18nn_6d8f9b0c398319ff')" prop="isBattery">
							<el-switch
							  v-model="form.isBattery"
							  active-text="YES"
							  inactive-text="NO"
								active-value="1"
								inactive-value="0">
							</el-switch>
							
						</el-form-item> -->

					</div>
					<div style="width:50%;">
						<!-- <el-card  shadow="never"> -->
						<el-form-item :label="$t('Storage.skuInfo.SKU_pictures')" prop="">
							<!-- <HyUpLoadImg ref="hyUpLoadImg1" :imgId="UserInfo.id" :imgwidth="200" :imgheight="200" :folder="'sku/'"
								:imgKey="'WhSkuInfo'" :maxSizeMB="5" :size="''" :hasRemove="true"
								v-on:changeImgUrl="hyUpLoadImg1" @removeImg="removeImgUrl">
								<div slot="imgDesc">
									<div style="padding:10px; width: 200px;">
										{{ $t('Storage.skuInfo.pic_size_tips') }}
										<br />
										{{ $t('Storage.skuInfo.pic_size_type') }}
										<br />
									</div>
								</div>
							</HyUpLoadImg> -->
							
							<el-button type="primary" size="small" icon="el-icon-paperclip"
								@click="openAddFile()">{{$t('i18nn_06f0c71344e7183c')}}</el-button>
							<!-- <ul> -->
							<!-- <li v-for="(item2,index2) in form.imgList" :key="index2"> -->
							<div v-if="form.goodsImg">
								<el-image style="width: 100px; height: 100px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
									:src="form.goodsImg" :preview-src-list="[form.goodsImg]">
									<div slot="error" class="image-slot">
										<i class="el-icon-document"></i>
									</div>
								</el-image>
								<!-- <a :href="item2.imgUrl" :title="item2.imgUrl" target="_blank">{{ item2.fileName }}</a> -->
								
								<el-button @click="delFile($event)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								<!-- </li> -->
								<!-- </ul> -->
							</div>
							
						</el-form-item>
						<!-- </el-card> -->

					</div>
				</div>

				<!-- <h3 class="titSty1">{{$t('Storage.packing_list.basic_information')}}</h3> -->


				<!-- <h3 class="titSty1">{{$t('i18nn_877c5a0e44a0eb07')}}</h3> -->




				<!-- <el-form-item :label="$t('Storage.skuInfo.SKU_title')" prop="goodsName">
          <el-input type="text" v-model="form.goodsName" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">建议不超过50字符</span>
        </el-form-item> -->

				<!-- <el-form-item label="SKU英文标题：" prop="goodsNameEn">
          <el-input type="text" v-model="form.goodsNameEn" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">建议不超过50字符</span>
        </el-form-item> -->

				<!-- <h3 class="titSty1">{{$t('Storage.skuInfo.price')}}信息</h3> -->

				<!-- <h3 class="titSty1">{{$t('i18nn_d48bb83a82237f60')}}</h3> -->

				<!-- <el-form-item label="重量单位：" prop="weightUnit">
          <el-select filterable clearable size="mini" v-model="form.weightUnit" style="width: 300px;" :placeholder="$t('FormMsg.Please_select')">
            <el-option v-for="item in selectOption.wh_weight_unit" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
          </el-select>
        </el-form-item> -->

				<!-- <h3 class="titSty1">规格信息(体积)</h3> -->
				<el-form-item :label="$t('Storage.skuInfo.unit')" prop="sizeUnit">
					<el-select filterable clearable size="" v-model="form.sizeUnit" style="width: 200px;"
						:placeholder="$t('FormMsg.Please_select')">
						<el-option v-for="item in selectOption.wh_size_unit" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code"></el-option>
					</el-select>
				</el-form-item>

				<!-- <el-card class="">
          <div slot="header">
            <span>{{$t('i18nn_f340f0ba85ea230d')}}</span>
          </div>
          <div >
            <el-form-item :label="$t('Storage.skuInfo.length')" prop="goodsLength">
              <el-input-number size="mini" v-model="form.goodsLength" controls-position="right" style="width: 300px;"></el-input-number>
            </el-form-item>
            <el-form-item :label="$t('Storage.skuInfo.width')" prop="goodsWidth">
              <el-input-number size="mini" v-model="form.goodsWidth" controls-position="right" style="width: 300px;"></el-input-number>
            </el-form-item>
            <el-form-item :label="$t('Storage.skuInfo.height')" prop="goodsHeight">
              <el-input-number size="mini" v-model="form.goodsHeight" controls-position="right" style="width: 300px;"></el-input-number>
            </el-form-item>
          </div>
        </el-card> -->
				<!-- <el-card class="" style="width:500px;" shadow="never"> -->
				<!-- <div slot="header">
						<span>{{$t('i18nn_f340f0ba85ea230d')}}</span>
						<span>(CM)</span>
					</div> -->
				<!-- <div> -->
				<!-- <div style="font-size:16px;font-weight:bold;color:#333;">
							<span>{{$t('i18nn_f340f0ba85ea230d')}}</span>
							<span>(CM)</span>
						</div> -->
				<!-- <h3 class="titSty1">体积(CM)</h3>
						<div style="display:flex;">
							<div style="">
								<el-form-item :label="$t('Storage.skuInfo.length')" prop="goodsLength">
									<el-input-number size="mini" v-model="form.goodsLength" controls-position="right"
										style="width: 100px;"></el-input-number>
								</el-form-item>
							</div>
							<div class="" style="text-align:center;">x</div>
							<div style="">
								<el-form-item :label="$t('Storage.skuInfo.width')" prop="goodsWidth">
									<el-input-number size="mini" v-model="form.goodsWidth" controls-position="right"
										style="width: 100px;"></el-input-number>
								</el-form-item>
							</div>
							<div class="" style="text-align:center;">x</div>
							<div style="">
								<el-form-item :label="$t('Storage.skuInfo.height')" prop="goodsHeight">
									<el-input-number size="mini" v-model="form.goodsHeight" controls-position="right"
										style="width: 100px;"></el-input-number>
								</el-form-item>
							</div>
						</div> -->

				<el-form-item :label="$t('i18nn_f340f0ba85ea230d')" required>
					<span slot="label">
						<!-- <span v-if="'1'==form.sizeUnit">体积(CM)</span>
								<span v-else-if="'2'==form.sizeUnit">体积(LB)</span> -->
						<span>{{$t('i18nn_f340f0ba85ea230d')}}</span>
					</span>
					<div style="width:350px;">
						<div style="display:flex; justify-content: space-around;">
							<div>
								<span class="red">*</span>
								<span>{{$t('Storage.skuInfo.length')}}</span>
							</div>
							<div>
								<span class="red">*</span>
								<span>{{$t('Storage.skuInfo.width')}}</span>
							</div>
							<div>
								<span class="red">*</span>
								<span>{{$t('Storage.skuInfo.height')}}</span>
							</div>
						</div>
						<div style="display:flex;justify-content: space-around;">
							<div>
								<el-input-number size="" v-model="form.goodsLength" controls-position="right"
									style="width: 120px;"></el-input-number>
							</div>
							<div>
								&nbsp;X&nbsp;
							</div>
							<div>
								<el-input-number size="" v-model="form.goodsWidth" controls-position="right"
									style="width: 120px;"></el-input-number>
							</div>
							<div>
								&nbsp;X&nbsp;
							</div>
							<div>
								<el-input-number size="" v-model="form.goodsHeight" controls-position="right"
									style="width: 120px;"></el-input-number>
							</div>
						</div>
					</div>

				</el-form-item>

				<!-- </el-row> -->
				<!-- </div> -->

				<!-- </el-card> -->

				<!-- <h3 class="titSty1">{{$t('i18nn_004617dc47191cb8')}}</h3> -->
				<!-- <el-card class="" style="width:500px;" shadow="never"> -->
				<!-- <div slot="header">
						<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>
						<span>(KG)</span>
					</div> -->
				<!-- <h3 class="titSty1">{{$t('Storage.skuInfo.weight')}}(KG)</h3> -->
				<div>
					<el-form-item :label="$t('Storage.skuInfo.weight')" prop="goodsWeight">
						<span slot="label">
							<!-- <span v-if="'1'==form.sizeUnit">{{$t('Storage.skuInfo.weight')}}(KG)</span>
								<span v-else-if="'2'==form.sizeUnit">{{$t('Storage.skuInfo.weight')}}(INC)</span> -->
							<span>{{$t('Storage.skuInfo.weight')}}</span>
						</span>
						<!-- <el-input type="text" v-model="form.putTitle" :placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
						<el-input-number size="" v-model="form.goodsWeight" controls-position="right" style="width: 200px;">
						</el-input-number>
					</el-form-item>
				</div>

				<!-- </el-card> -->
				<el-row>
					<el-col :span="8">
						<el-form-item :label="$t('Storage.skuInfo.safety_stock')" prop="safeStock">
							<el-input-number size="" v-model="form.safeStock" controls-position="right" style="width: 200px;">
							</el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="$t('Storage.skuInfo.price')" prop="declarePrice">
							<el-input-number size="" v-model="form.declarePrice" controls-position="right"
								style="width: 200px;"></el-input-number>
							<span class="form_msg">USD</span>
						</el-form-item>
					</el-col>
					<el-col :span="8"></el-col>
				</el-row>

				<el-form-item label="UPC" prop="">
					<el-input type="text" v-model.trim="form.upcBrand" style="width: 400px;"
						:placeholder="$t('FormMsg.Please_Enter')" maxlength="40" show-word-limit></el-input>
				</el-form-item>

				<!-- <h3 class="titSty1">{{$t('Storage.packing_list.Other')}}</h3> -->
				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :row="5" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
						:maxlength="1000" show-word-limit style="width: 800px"></el-input>
				</el-form-item>
			</el-form>
			<!-- <div>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '1')" v-else>{{$t('i18nn_0ddb67d8d6d01ad4')}}</el-button>
      </div> -->
			<div class="drawer-footer">
				<!-- <el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button> -->
				<el-button type="primary" icon="el-icon-tickets"
					@click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>
		<!-- </el-dialog> -->

		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('Storage.skuInfo.Import_SKU')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogUploadVisible" width="1200px" top="0" v-loading="loadingExcel"> -->
		<el-drawer :wrapperClosable="false" :title="$t('Storage.skuInfo.Import_SKU')" append-to-body
			:visible.sync="dialogUploadVisible" :direction="'rtl'" size="1000px" v-loading="loadingExcel">
			<!-- <div>
				<input style="display: none;" type="file" id="" ref="file" @change="fileChange($event)"
					:accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'" />
				<el-button type="success" size="small" icon="el-icon-plus" @click="openExcel">
					{{ $t('module.upload.Select_Excel') }}</el-button>
				<span style="display: inline-block; width: 200px;">{{ excelFileName }}</span>
				<el-button type="primary" size="small" icon="el-icon-upload" @click="uploadExcel">
					{{ $t('module.upload.Upload_preview') }}</el-button>
				<span style="padding-left: 100px;">
					<el-link
						:href="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/skuImportMod.xlsx?random=' + Math.random()"
						target="_blank" type="primary" icon="el-icon-download">
						{{ $t('module.upload.Download_template') }}
					</el-link>
				</span>
			</div> -->

			<uploadExcelBtn :openTime="openTimeUpExc" :readHttp="$urlConfig.WhMyGoodsSkuExUpload"
				:templateUrl="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/skuImportModV2.xlsx'"
				@uploadSuccess="uploadSuccess">
			</uploadExcelBtn>

			<el-alert style="margin: 10px 0;" :title="$t('Storage.skuInfo.sku_explain')" type="warning" :closable="false">
			</el-alert>
			<div style="overflow: auto; width: 100%; height: 80%;">
				<!-- <img :src="imgUrlBigShow" width="auto" height="auto" /> -->
				<el-table :data="excelData" stripe :border="true" :max-height="$store.state.frameConHeightWh1"
					style="width: 100%" size="small">
					<el-table-column type="index" fixed="left" width="50" align="center"
						:label="$t('Storage.tableColumn.no')"></el-table-column>
					<!-- <el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column> -->

					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')"></el-table-column>
					<!-- <el-table-column prop="goodsSku" label="SKU"></el-table-column> -->
					<el-table-column prop="sysGoodsSku" label="SKU"></el-table-column>
					<!-- <el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column> -->
					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

					<el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
					<el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
					<el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>
					<!-- <el-table-column prop="isBatteryName" :label="$t('i18nn_6d8f9b0c398319ff')">
					</el-table-column> -->
					<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
					<!-- <el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column> -->

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover">
								<div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>

					<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right"> -->
					<!-- <template slot-scope="scope"> -->
					<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div>
                 <div v-else>
                   <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
                   <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
                   </div> -->
					<!-- </template> -->
					<!-- </el-table-column> -->
				</el-table>
			</div>
			<div class="drawer-footer">
				<!-- <el-button type="primary" plain @click="dialogUploadVisible = false">{{ $t('FormMsg.Close') }}
				</el-button> -->
				<el-button type="primary" icon="el-icon-tickets" @click="submitExcelForm()">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>
		<!-- </el-dialog> -->

		<!-- 输入框 -->
		<el-dialog :title="'SKU输入'" append-to-body :close-on-click-modal="false" :visible.sync="dialogSkuInputVisible"
			width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.skuNumberListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个SKU'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSkuInputVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureSkuInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		<!-- 导出一维码code -->
		<WhSkuCodeExport :openTime="CodeExportOpentTime" :filter="pageFilterData()" @success="CodeExportSuccess">
		</WhSkuCodeExport>
		
		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :maxSizeMB="5" :limit="1" :accept="'image/png,image/x-png,image/jpg,image/jpeg,image/gif'"
			:folder="'sku/'" :openTime="FileUploadOpenTime" @success="FileUploadSuccess">
		</dialogFileUpload>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhMyGoodsSkuPageList"
			:expHttpFilter="pageFilterData()" :warningMsg="$t('Storage.skuInfo.sku_export_img_explain')"></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		WSkuInfo_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	import JQ from 'jquery';

	import {
		FormatTableSort
	} from '@/utils/utils.js';
	
	// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import barcode from '@/components/Common/barcode.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';

	import uploadExcelBtn from '@/components/StorageCenter/components/uploadExcelBtn.vue';

	import WhSkuCodeExport from '@/components/StorageCenter/WSkuProduct/WhSkuCodeExport.vue';
	// import HyUpLoadImg from '@/components/Common/HyUpLoadImg.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			// CommodityCateLinkage,
			barcode,
			SelAgentUser,
			whExcelCustom,
			uploadExcelBtn,
			WhSkuCodeExport,
			dialogFileUpload
			// HyUpLoadImg
		},
		props: {
			// mobile:"",
			isSel: {
				default: function() {
					return false;
				},
				type: Boolean
			},
			status: {
				default: function() {
					return '';
				},
				type: String
			},
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				CodeExportOpentTime: '',
				
				FileUploadOpenTime: '',
				// activeName: 'second',
				// isShowFrom:false,
				// pickerOptions1: {
				//     disabledDate(time) {
				//       return time.getTime() > Date.now();
				//     },
				//     shortcuts: [{
				//       text: this.$t('i18nn_0200bd47bb4bb83d'),
				//       onClick(picker) {
				//         picker.$emit('pick', new Date());
				//       }
				//     }, {
				//       text: this.$t('i18nn_84b73bfc6dada445'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24);
				//         picker.$emit('pick', date);
				//       }
				//     }, {
				//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
				//         picker.$emit('pick', date);
				//       }
				//     }]
				//   },
				UserInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				//导入excel
				dialogUploadVisible: false,
				loadingExcel: false,
				// fileExcel: '',
				excelData: [],
				// excelFileName: '',

				openTimeUpExc: "",

				//输入弹窗
				dialogSkuInputVisible: false,

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				// CateValue: [],
				loading: false,
				form: {
					id: null, //"数据ID",
					// goodsSubjectId: '', //this.$t('i18nn_268e50d838264ca8'),
					goodsSku: '', //"SKU编码",
					upcBrand: '', //UPC
					goodsName: '', //"SKU名称",
					goodsNameEn: '', //"SKU英文标题",
					sysGoodsSku: "", //自定义SKU
					isBattery: "0", //是否含电池
					goodsImg: '', //"SKU图片",
					declarePrice: '', //this.$t('i18nn_9db48ed0e8ec64a6'),
					goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
					sizeUnit: '1', //this.$t('i18nn_38bc71c0dc55904b'),
					goodsLength: '', //this.$t('i18nn_0e60303b30d5d4b4'),
					goodsWidth: '', //this.$t('i18nn_6cd31871f8528dd5'),
					goodsHeight: '', //this.$t('i18nn_93c9ea4a0e52c641'),
					safeStock: '' //this.$t('i18nn_004617dc47191cb8')
				},

				formRules: {
					// goodsSubjectId: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					goodsSku: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					sysGoodsSku: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					isBattery: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					goodsName: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					goodsNameEn: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],

					goodsImg: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					declarePrice: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					goodsWeight: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					sizeUnit: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					goodsLength: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					goodsWidth: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					goodsHeight: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					safeStock: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}]
				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type:[],
					wh_size_unit: [],
					statusList: WSkuInfo_status
				},
				//查询，排序方式
				filterData: {
					// orderBy: 'goods_sku', //排序字段
					// sortAsc: 'N', //desc降序，asc升序

					orderBy: 'create_time', //排序字段
					sortAsc: 'N', //desc降序，asc升序

					agentUser: '',
					// "accountPeriod":"",
					// "billNo":""
					status: '',
					// putWhNo: '',
					goodsSku: '',

					skuNumberList: [],
					skuNumberListStr: '',

					// sysGoodsSku:"",
					// hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_size_unit'],
				(data) => {
					this.selectOption.wh_size_unit = data['wh_size_unit'];
				});
			if (!!this.status) {
				this.filterData.status = this.status;
			}
			this.initData();
		},
		methods: {
			initData() {

				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},

			exportExcelAction() {
				let columns = [{
						title: this.$t('i18nn_6cdece641436d7ab'),
						key: 'statusName'
					}, {
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: 'SKU CODE',
						key: 'goodsSkuImg',
						types: 'image'
					},

					// {
					// 		title: '自定义SKU',
					// 		key: 'sysGoodsSku'
					// 	},
					// {
					// 	title: this.$t('i18nn_6d8f9b0c398319ff'),
					// 	key: 'isBatteryName'
					// },
					{
						title: this.$t('Storage.skuInfo.SKU_title'),
						key: 'goodsName'
					},
					// {
					// 	title: this.$t('Storage.skuInfo.English_title'),
					// 	key: 'goodsNameEn'
					// },


					{
						title: this.$t('Storage.skuInfo.unit'),
						key: 'sizeUnitName'
					},
					{
						title: this.$t('Storage.skuInfo.length'),
						key: 'goodsLength'
					},
					{
						title: this.$t('Storage.skuInfo.width'),
						key: 'goodsWidth'
					},
					{
						title: this.$t('Storage.skuInfo.height'),
						key: 'goodsHeight'
					},
					{
						title: this.$t('Storage.skuInfo.weight'),
						key: 'goodsWeight'
					},
					{
						title: this.$t('Storage.skuInfo.price'),
						key: 'declarePrice'
					},
					{
						title: this.$t('Storage.skuInfo.safety_stock'),
						key: 'safeStock'
					},
					{
						title: 'UPC',
						key: 'upcBrand'
					},
					{
						title: '备注',
						key: 'remark'
					},
					// {
					// 	title: this.$t('hytxs0000037'),
					// 	key: 'totalStock',
					// 	types: 'custom',
					// 	formatter:function(row){
					// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
					// 			return row.placeStockTotal.totalStock
					// 		} else {
					// 			return 0;
					// 		}

					// 	}
					// },
				];
				// let Data = this.tableDataCatch;
				// return columns;
				let Data = this.tableData;

				let data_dom = JQ('#ex_table').find('.el-table__body');
				let obj_key_img = {};
				// console.log(data_dom.find("tr"));
				data_dom.find('tr').each((index, domEle) => {
					// console.log("index",index);
					let imgObj = JQ(domEle).find('td .cell img');
					// console.log("imgObj",imgObj);
					if (imgObj) {
						obj_key_img[imgObj.attr('code')] = imgObj.attr('src');
						obj_key_img[imgObj.attr('code') + '_ExImgWidth'] = imgObj.width();
						obj_key_img[imgObj.attr('code') + '_ExImgHeight'] = imgObj.height();
					}
				});
				// console.log("obj_key_img",obj_key_img);
				Data.forEach(item => {
					item.goodsSkuImg = obj_key_img[item.goodsSku];
					item.ExImgWidth = obj_key_img[item.goodsSku + '_ExImgWidth'];
					item.ExImgHeight = obj_key_img[item.goodsSku + '_ExImgHeight'];
				});
				// console.log('Data', Data);
				// return;
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'wh_sku';
				this.excelOption = {};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'wh_sku');
			},
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			//打开的输入框
			openSkuInputAction() {
				// this.filterData.orderNumberListStr = "";
				this.dialogSkuInputVisible = true;
			},
			//确定的输入框
			sureSkuInputAction() {
				this.dialogSkuInputVisible = false;
				this.filterData.goodsSku = '';
				let skuNumberList = [];
				if (this.filterData.skuNumberListStr) {
					skuNumberList = this.filterData.skuNumberListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.skuNumberList = skuNumberList;
				this.initData();
			},
			//清除搜索的值
			clearSkuList() {
				this.filterData.skuNumberListStr = '';
				this.filterData.skuNumberList = [];
				this.initData();
			},

			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			openDioalog(formParm) {
				// console.log(formParm);
				this.dialogFormVisible = true;
				let form = Object.assign({}, formParm);
				console.log('form', form);
				// // 重置
				this.resetForm('form');
				if (null === formParm) {
					//新增

					// this.form.weightUnit = '1';
					// this.form.volumeUnit = '1';
					// this.form.whFeeType = '1';


					this.dialogFormStatus = 0;
					// form.subUserId = null;
					// form.userSubUserId = null;
					// form.state = true;
					//浅拷贝、对象属性的合并
					form.sizeUnit = '1';
					form.isBattery = '0';
					form.safeStock = '1';
					form.declarePrice = '1';
					this.form = form;
				} else {
					//修改
					this.dialogFormStatus = 1;
					// form.state = form.state === '0' ? true : false;
					// this.form.goodsSubjectId = this.CateValue[0];
					// this.CateValue = [form.goodsSubjectId];
					//浅拷贝、对象属性的合并
					this.form = form;
					// this.$nextTick(() => {
					// 	// console.log('this.$refs.hyUpLoadImg1 111',this.$refs.hyUpLoadImg1,this.form.goodsImg);
					// 	if (this.$refs.hyUpLoadImg1 && this.form.goodsImg) {
					// 		// console.log('this.$refs.hyUpLoadImg1  22',this.$refs.hyUpLoadImg1,this.form.goodsImg);
					// 		this.$refs.hyUpLoadImg1.initUrl(this.form.goodsImg);
					// 	};
					// })

				}
				// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
				//       type: 'warning',
				//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				//     });
			},
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				this.openDioalog(row, this.$t('FormMsg.Edit'));
			},

			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						this.delDataAction(event, row);
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				this.postData(this.$urlConfig.WhMyGoodsSkuDel + '/' + row.id, {}, 'delete', () => {

				});
			},

			// hyUpLoadImg1: function(childValue) {
			// 	// childValue就是子组件传过来的值
			// 	this.form.goodsImg = childValue;
			// },
			// removeImgUrl() {
			// 	this.form.goodsImg = "";
			// 	this.$nextTick(() => {
			// 		if (this.$refs.hyUpLoadImg1) {
			// 			this.$refs.hyUpLoadImg1.initUrl("");
			// 		}
			// 	})
			// },
			
			//添加附件
			openAddFile() {
				this.FileUploadOpenTime = new Date().getTime();
			},
			
			//删除附件
			delFile(event) {
				event.stopPropagation();
				// this.form.imgList.splice(index, 1);
				this.form.goodsImg = "";
			},
			
			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						// url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
						imgUrl: item.filePath,
					}
				});
			
				if (fileList[0] && fileList[0].imgUrl) {
					this.form.goodsImg = fileList[0].imgUrl;
				} else {
					this.form.goodsImg = "";
				}
			
				this.$forceUpdate();
			
			},

			//打开弹窗 下载二维码 PDF
			openDownCodePDF() {
				console.log('openDownCodePDF');
				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
				// 	return;
				// }
				this.CodeExportOpentTime = new Date().getTime();
			},
			CodeExportSuccess() {

			},

			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },
			//商品类目变化
			// CateChang(v) {
			// 	// this.onChangeNoSave(true);

			// 	this.CateValue = v;

			// 	this.form.goodsSubjectId = this.CateValue[0];

			// 	// this.baseInfo.commodityCategories.first = this.CateValue[0];
			// 	// this.baseInfo.commodityCategories.second = this.CateValue[1];
			// 	// this.baseInfo.commodityCategories.third = this.CateValue[2];

			// 	//该类目商品基本属性
			// 	// this.getCommodityAttribute();
			// },
			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						if (0 === this.dialogFormStatus) {
							formData.id = null;
							formData.userId = this.UserInfo.id;

							this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData, '', () => {
								// this.$router.push({name:"WSkuAddSuccessPage"});
								this.dialogFormVisible = false;
								this.initData();
							});

						} else {
							formData.userId = this.UserInfo.id;
							this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData, '', () => {
								// this.$router.push({name:"WSkuAddSuccessPage"});
								this.dialogFormVisible = false;
								this.initData();
							});

						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				// this.CateValue = [];
				// this.$nextTick(() => {
				// 	if (this.$refs.hyUpLoadImg1) {
				// 		this.$refs.hyUpLoadImg1.initUrl('');
				// 	}
				// })
			},

			//提交信息
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						//   type: 'success',
						//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// });
						// this.$message.success(this.$t('tips.submitSuccess'));
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						}
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			// clearFile() {
			// 	// this.fileExcel = null;
			// 	// this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },
			//打开导入弹窗
			openExcelDialog() {
				this.dialogUploadVisible = true;
				this.openTimeUpExc = new Date().getTime();
				this.excelData = [];
				// this.clearFile();
			},

			//上传数据回调
			uploadSuccess(data) {
				// this.form.transferOrders = data.rows;
				// this.$forceUpdate();

				this.excelData = data;
				// this.clearFile();
				// this.$emit('parsingSuccess', this.excelData);
			},

			// fileChange(e) {
			// 	// this.loading = true;
			// 	console.log(e);

			// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			// 	this.excelData = [];
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	if (!this.$refs.file || !this.$refs.file.files) {
			// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
			// 	if (this.$refs.file.files[0]) {
			// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			// 			this.fileExcel = this.$refs.file.files[0];
			// 			this.excelFileName = this.fileExcel.name;
			// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			// 			// this.uploadExcel();
			// 		} else {
			// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
			// 		}
			// 		// } else {
			// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			// 		//   });
			// 		// }
			// 	} else {
			// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			// 		// });
			// 	}
			// },

			// openExcel() {
			// 	// this.initExcelData();
			// 	// this.$nextTick(()=>{
			// 	try {
			// 		this.$refs.file.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// 	// });
			// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
			// 	var comment = this.$refs.file;
			// 	if (document.all) {
			// 		// For IE
			// 		comment.click();
			// 	} else if (document.createEvent) {
			// 		// 在ff中要为a标签添加click事件，并且侦听该事件
			// 		var ev = document.createEvent('MouseEvents');
			// 		ev.initEvent('click', false, true);
			// 		comment.dispatchEvent(ev);
			// 	} else {
			// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 	}
			// },
			//导入excel
			// uploadExcel() {
			// 	if (!this.fileExcel) {
			// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			// 		return;
			// 	}

			// 	this.loadingExcel = true;

			// 	let file = this.fileExcel;
			// 	var formdata = new FormData();
			// 	formdata.append('file', file);

			// 	this.$http
			// 		.post(this.$urlConfig.WhMyGoodsSkuExUpload, formdata, {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data'
			// 			}
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('tips.submitSuccess'));
			// 			console.log(data);
			// 			this.loadingExcel = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.excelData = data.rows;
			// 				// if('1'==data.data.flag){
			// 				//   this.excelData = data.data.data;
			// 				// } else {
			// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 				//     type: 'warning',
			// 				//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				//   });
			// 				// }
			// 				this.clearFile();
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				});
			// 			}
			// 			// if (200 == data.code && data.data) {
			// 			//   if('1'==data.data.flag){
			// 			//     this.excelData = data.data.data;
			// 			//   } else {
			// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 			//       type: 'warning',
			// 			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//     });
			// 			//   }

			// 			// } else {
			// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			// 			//     type: 'warning',
			// 			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//   });
			// 			// }
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingExcel = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		});
			// },
			//提交-导入excel
			submitExcelForm() {
				this.loadingExcel = true;
				this.$http
					.put(this.$urlConfig.WhMyGoodsSkuExSubmit, this.excelData)
					.then(({
						data
					}) => {
						console.log(this.$t('tips.submitSuccess'));
						console.log(data);
						this.loadingExcel = false;
						if (200 == data.code && data.data) {
							if ('1' == data.data.flag) {
								// this.excelData = data.data.data;
								this.dialogUploadVisible = false;
								this.excelData = [];
								// this.excelFileName = '';
								// this.fileExcel = null;

								this.initData();
								// this.$alert(this.$t('i18nn_b93f7c8c4e2346f8'), this.$t('tips.tipsTitle'), {
								//   type: 'success',
								//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
								// });
								this.$message.success(this.$t('tips.submitSuccess'));
							} else {
								this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
									type: 'warning'
									//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
								});
							}
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingExcel = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			// initData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			//商品类目变化
			// CateChang2(v) {
			// 	// this.onChangeNoSave(true);

			// 	// this.CateValue = v;

			// 	this.filterData.subjectName = this.CateValue[0];
			// 	this.initData();
			// 	// this.baseInfo.commodityCategories.first = this.CateValue[0];
			// 	// this.baseInfo.commodityCategories.second = this.CateValue[1];
			// 	// this.baseInfo.commodityCategories.third = this.CateValue[2];

			// 	//该类目商品基本属性
			// 	// this.getCommodityAttribute();
			// },
			//排序改变
			sortChange({
				column,
				prop,
				order
			}) {
				console.log('sortChange', {
					column,
					prop,
					order
				});
				let sortData = FormatTableSort({
					column,
					prop,
					order
				});
				this.filterData.orderBy = sortData.orderBy;
				this.filterData.sortAsc = sortData.sortAsc;
				this.getPageData();
			},

			//分页的筛选项数据
			pageFilterData() {

				let skuNumberList = [];

				if (this.filterData.skuNumberListStr) {
					skuNumberList = this.filterData.skuNumberList;
				} else if (this.filterData.goodsSku) {
					skuNumberList = [this.filterData.goodsSku];
				}

				return {
					"sortAsc": this.filterData.sortAsc,
					"orderBy": this.filterData.orderBy,

					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					// subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,

					goodsSkuList: skuNumberList.length > 0 ? skuNumberList : null,

					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhMyGoodsSkuPageList, filterData)
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			selRow(event, row) {
				event.stopPropagation();
				this.$emit('selectRow', row);
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_size_unit'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
		line-height: 150%;
	}
</style>